var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BackBtn',{attrs:{"go_to":_vm.leads_link}}),_c('PageTitle',{attrs:{"hide_btns":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.current_lead.lead_number)+" ")]},proxy:true}])}),(_vm.info_message())?_c('InfoAlert',{staticClass:"mb-5",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}})]},proxy:true}],null,false,631939790)}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"8"}},[_c('v-card',{class:_vm.$style.card},[_c('v-form',{ref:"form",attrs:{"disabled":_vm.is_disabled_lead},on:{"submit":function($event){$event.preventDefault();return _vm.save_and_update.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('div',{class:[_vm.$style.itemsRow, 'mb-0']},[(_vm.is_show_category_select)?_c('Select',{class:_vm.$style.item,attrs:{"items":_vm.categories,"label":"Категория","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"require":"","blue_icon":""},model:{value:(_vm.lead.lead_category_id),callback:function ($$v) {_vm.$set(_vm.lead, "lead_category_id", $$v)},expression:"lead.lead_category_id"}}):_vm._e()],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('Select',{class:_vm.$style.item,attrs:{"items":_vm.leads_statuses,"label":"Статус","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"require":"","blue_icon":""},model:{value:(_vm.lead_status_model),callback:function ($$v) {_vm.lead_status_model=$$v},expression:"lead_status_model"}}),(_vm.is_sale_status)?_c('TextField',{directives:[{name:"money",rawName:"v-money",value:(_vm.money_settings),expression:"money_settings"}],class:_vm.$style.item,attrs:{"hide-details":"","label":"Сумма продажи","rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_valid_sum(v) || 'Введите сумму продажи'; }
              ],"require":"","blue_icon":""},model:{value:(_vm.sale_amount),callback:function ($$v) {_vm.sale_amount=$$v},expression:"sale_amount"}}):_vm._e()],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('Textarea',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[function (v) { return _vm.textarea_rules(v); }],"label":"Комментарий","require":_vm.is_canceled_status,"blue_icon":_vm.is_canceled_status},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('v-btn',{attrs:{"depressed":"","large":"","type":"submit","color":"accent darken-4","disabled":!_vm.form_valid || _vm.changed_flag}},[_vm._v(" Сохранить изменение ")])],1)])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Tabs',{on:{"change":function($event){_vm.tabs_model = $event}},model:{value:(_vm.tabs_model),callback:function ($$v) {_vm.tabs_model=$$v},expression:"tabs_model"}},[_c('v-tab',{staticClass:"text-uppercase"},[_vm._v(" Информация ")]),_c('v-tab',{staticClass:"text-uppercase"},[_vm._v(" История ")]),_c('v-tabs-items',{model:{value:(_vm.tabs_model),callback:function ($$v) {_vm.tabs_model=$$v},expression:"tabs_model"}},[_c('v-tab-item',[_c('LeadInfoTab',{staticClass:"mt-8 px-4 pb-4"})],1),_c('v-tab-item',[_c('HistoryTab',{staticClass:"mt-8 px-4 pb-4"})],1)],1)],1)],1)],1),(_vm.status_lead !== _vm.sale_status)?_c('v-snackbar',{attrs:{"color":"success lighten-1","outlined":"","timeout":"2000","top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.success_snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,3758000836),model:{value:(_vm.success_snackbar),callback:function ($$v) {_vm.success_snackbar=$$v},expression:"success_snackbar"}},[_c('div',{class:_vm.$style.snackbarContent},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.snack_msg)+" ")],1)]):_vm._e(),_c('SimpleDialog',{attrs:{"max-width":"564","bts_align":"end"},on:{"close":function($event){_vm.sale_dialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Лиду присвоен статус “Продажа” ")]},proxy:true},{key:"text",fn:function(){return [_vm._v(" Добавить лида в список клиентов? ")]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.sale_dialog = false}}},[_vm._v(" Отменить ")]),_c('v-btn',{attrs:{"depressed":"","color":"accent darken-4"},on:{"click":function($event){_vm.client_dialog = true}}},[_vm._v(" Добавить ")])]},proxy:true}]),model:{value:(_vm.sale_dialog),callback:function ($$v) {_vm.sale_dialog=$$v},expression:"sale_dialog"}}),_c('AddNewClientDialog',{key:_vm.client_dialog,attrs:{"persistent":"","edit_client":_vm.lead_to_client},on:{"submit":_vm.add_client,"close":_vm.close_client_dialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Добавление клиента ")]},proxy:true}]),model:{value:(_vm.client_dialog),callback:function ($$v) {_vm.client_dialog=$$v},expression:"client_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }